import React from 'react';
import styled from 'styled-components';
import Img from 'gatsby-image';
import { getFluidGatsbyImage } from 'gatsby-source-sanity';

export default ({ node }) => {
  if (!node || !node.asset || !node.asset._id) {
    return null;
  }
  const fluidProps = getFluidGatsbyImage(
    node.asset._id,
    { maxWidth: 1440 },
    {
      projectId: process.env.GATSBY_SANITY_PROJECT_ID,
      dataset: process.env.GATSBY_SANITY_DATASET
    }
  );

  return (
    <Figure>
      <Img fluid={fluidProps} alt={node.alt} />
      <figcaption>{node.caption}</figcaption>
    </Figure>
  );
};

const Figure = styled.figure`
  border-radius: 5px;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 4px 17px -5px;
  margin: 22px auto;
  max-width: 90%;
  overflow: hidden;
`;
